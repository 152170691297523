<template>
  <full-view
    :title="$t('app.route.savedAddresses')"
    :fab="true"
    :forth-button="initAccount"
    :back-button="!initAccount"
    @fab:click="newAddress"
  >
    <template v-slot:toolbar>
      <v-stepper v-if="initAccount" v-model="step" class="secondary" style="box-shadow: none;">
        <v-stepper-header>
          <v-stepper-step :complete="true" step="1" color="secondary darken-3">
            <div class="v-stepper__label" style="color: #4d977b;">Select Your Region</div>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="false" step="2">
            Add Address
          </v-stepper-step>
        </v-stepper-header>
        <!-- <v-stepper-header>
          <v-stepper-step :complete="true" step="1">
            Select Your Region
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="getCurrentUser.activeAddress" step="2" color="primary">
            Add Your Address
          </v-stepper-step>
        </v-stepper-header> -->
      </v-stepper>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 pt-0" :style="getViewHeightStyle(56)">
        <p class="px-3 pt-2 body-2" v-if="!getCurrentUser.activeAddress">
          You haven't registered your address yet, please define one now:
        </p>

        <v-list two-line class="pt-0">
          <div
            v-for="(address, i) in sortItemsBy(getAddresses, 'active')"
            :key="i"
          >
            <v-list-item link :class="i % 2 === 0 ? 'white' : 'grey lighten-5'">
              <v-list-item-avatar>
                <v-icon large>mdi-map-marker</v-icon>
              </v-list-item-avatar>
              <v-list-item-content
                @click="
                  () =>
                    $$router.push({
                      name: 'address',
                      params: { addressId: address.id },
                    })
                "
              >
                <v-list-item-title v-text="address.title" />
                <v-list-item-subtitle>{{
                  getAddressString(address)
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex flex-space-between">
                  <v-btn fab icon @click="setDetault(address)">
                    <v-icon v-if="address.active" large color="primary"
                      >mdi-home-circle-outline</v-icon
                    >
                    <v-icon v-if="!address.active" large
                      >mdi-circle-outline</v-icon
                    >
                  </v-btn>
                  <v-divider vertical class="mx-3" />
                  <v-btn fab icon @click="confirmDelete(address)">
                    <v-icon color="red lighten-2" large>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
            <!-- <v-divider
              v-if="
                notEmptyArray(getAddresses) && i !== getAddresses.length - 1
              "
            /> -->
          </div>
        </v-list>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FullView from "@/common/layouts/FullView";

export default {
  name: "SavedAddresses",
  components: { FullView },
  data() {
    return {
      initAccount: null,
      step: 2,
    };
  },
  mounted() {
    this.initAccount = !this.getCurrentUser.activeAddress;
    // this.initAccount = this.$route.params.initAccount;
    this.loadAddresses();
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("addresses", ["getAddresses"]),
  },
  methods: {
    ...mapActions("addresses", [
      "loadAddresses",
      "updateAddress",
      "resetAddress",
      "deleteAddress",
    ]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    getAddressString(a) {
      return `${a.title} / ${a.buildingNumber} ${a.street}, ${a.area} (
        ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
        ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
    },
    newAddress() {
      this.$$router.push({
        name: "address",
        params: {
          id: "new",
          back: this.$route.params.back,
          initAccount: this.initAccount,
        },
      });
    },
    confirmDelete(address) {
      const dialog = {
        title: "Delete",
        text: `Are you sure you want to delete ${address.title} user?`,
        actions: [
          {
            text: "Yes",
            click: () => {
              this.deleteAddress(address.id).then(() => this.loadAddresses());
              this.closeDialog();
            },
            color: "red",
          },
          {
            text: "No",
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };

      this.showDialog(dialog);
    },
    setDetault(address) {
      this.updateAddress({
        url: "/default",
        item: address.id,
      }).then(() => this.loadAddresses());
    },
  },
};
</script>
